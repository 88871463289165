<template>
  <section>
    <div class="content-header">
      <h2>Following up after a difficult conversation</h2>
    </div>
    <div class="content-wrapper">
      <p>Here is an example of how you might follow up with students after a difficult conversation has taken place.</p>
      <div class="panel">
        <p>Hello [Course code],</p>
        <p>This week we discussed some difficult topics in class. If you would like to further discuss these topics, I welcome you to connect either by email or during office hours.</p>
        <p>There are many resources available to you as students, please see below for a list of open access resources:</p>
        <p>[List the resources available on your campus for peer support, counselling, support for bullying, harassment, or discrimination and academic concerns]</p>
      </div>

    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
